
// $column-width: (100% / 3);
$column-width: (100% / 2);

/* ---- grid ---- */

* { box-sizing: border-box; }

/* force scrollbar */
html { overflow-y: scroll; }

/* ---- grid ---- */

// .grid {
//     background: #9f0;
// }

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

@media(max-width:767px) {
    .grid-sizer {
        width: calc(100% - 20px);
    }

    .grid-item {
        width: calc(100% - 20px);
        float: left;
    }
    
    .grid-item--width2 {
        width: calc(100% - 20px);
    }
}

@media(min-width:767px) {
    .grid-sizer {
        width: $column-width;
    }

    .grid-item {
        width: calc(#{$column-width} - 20px);
        float: left;
    }
    
    .grid-item--width2 {
        width: calc(100% - 20px);
    }
}

.grid-item--header {
    width: 100%;
}
