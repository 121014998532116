
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'masonry';
@import 'mixins';
@import 'fonts';
// @import 'debug';

$img-assets: '../assets/';

/* ---- global ---- */
	body {
		background-image: url(#{$img-assets}interface/bg.gif);
		background-repeat: repeat;
		
		// font-size: 12pt;
	}

	h2 {
		// font-family: 'MuseoSans-500', 'Montserrat', Verdana, sans-serif;
		font-family: 5050, serif, 'MuseoSans-500', Montserrat, Verdana, sans-serif;
		// font-weight: bold;
		margin-top: 0;
	}
	
	canvas#header-canvas {
		// position: absolute;
		display: block;
		top: 0px;
		left: 0px;
		width: 100%;
		// background-color: #fff;
		// background-color: #333;
		background-color: #000;
		visibility: hidden;
		transform-origin: top left;	/** needed when the canvas is scaled by the app **/
	}
	
	.logo {
		margin: 0 auto;
	}
	
	.table tbody tr td {
		border-top: none;
		// border-bottom: 1px solid #ddd;
		padding: 5px 0px 0px 0px;
	}

/* ---- masonry grid ---- */
	.grid-item img {
		margin-bottom: 10px;
	}

	.grid-item {
		background: #fff;
		
		min-width: 340px;
		padding: 20px;
		margin-right: 10px;
		margin-left: 10px;
		margin-bottom: 20px;
		
		@include drop-shadow(0, 0, 5px, 2px, .15);
	}
	
	.grid-item--width2 {
		padding: 0px;
	}

/* ---- bootstrap carousel ---- */
	.carousel-indicators {
		bottom: 5px;
		// align right:
		left: auto;
		right: 0px;
		margin-right: 10px;
		width: inherit;
	}

	// Fade the bootstrap carousel instead of sliding it
	.carousel-fade {
		.carousel-inner {
			.item {
				transition-property: opacity;
			}
			
			.item,
			.active.left,
			.active.right {
				opacity: 0;
			}

			.active,
			.next.left,
			.prev.right {
				opacity: 1;
			}

			.next,
			.prev,
			.active.left,
			.active.right {
				left: 0;
				transform: translate3d(0, 0, 0);
			}
		}

		.carousel-control {
			z-index: 2;
		}
	}
