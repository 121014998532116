
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

@font-face {
	font-family: 'MuseoSans-500';
	src: url('../fonts/museo-sans-500.eot');
	src: url('../fonts/museo-sans-500.eot?#iefix')	format('embedded-opentype'),
	url('../fonts/museo-sans-500.woff2')			format('woff2'),
	url('../fonts/museo-sans-500.woff')				format('woff'),
	url('../fonts/museo-sans-500.ttf')				format('truetype');
}
